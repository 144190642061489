import {
  LocationOnOutlined,
  EmailOutlined,
  PhoneAndroidOutlined,
} from "@material-ui/icons";
import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ContactForm from "../components/ui/Contact/ContactForm/ContactForm";
import ContactInfoItem from "../components/ui/Contact/ContactInfo/ContactInfoItem";

import contactInfo from "../content/settings/contact_info.json";
import contactPageData from "../content/pages/contact.json";
import { formatPhoneNumber } from "../utils/formatPhoneNumber";

interface ContactPageProps {}

const ContactPage: React.FC<ContactPageProps> = ({}) => {
  return (
    <Layout>
      <SEO
        title={contactPageData.seo.seoTitle}
        description={contactPageData.seo.metaDescription}
        slug="contact"
      />

      <section className="bg-gray-800">
        <div className="container">
          <h1 className="text-4xl text-white mb-0 leading-none">Contact Us</h1>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="flex flex-wrap justify-between">
            <div className="sm:w-4/12 w-full mb-6 sm:mb-0">
              <h3 className="sm:text-3xl text-2xl">Contact Us</h3>
              <ul>
                <ContactInfoItem icon={LocationOnOutlined} title="Address">
                  <li>{contactInfo.address.street}</li>
                  <li>
                    <span className="mr-1">{contactInfo.address.city}</span>
                    <span>{contactInfo.address.zipCode}</span>
                  </li>
                  <li>{contactInfo.address.state}</li>
                </ContactInfoItem>
                <ContactInfoItem icon={EmailOutlined} title="Email">
                  <li>
                    <a
                      className="hover:text-primary-main"
                      href={`mailto:${contactInfo.email}`}
                    >
                      {contactInfo.email}
                    </a>
                  </li>
                </ContactInfoItem>
                <ContactInfoItem icon={PhoneAndroidOutlined} title="Phone">
                  <li>
                    <a
                      className="hover:text-primary-main"
                      href={`tel:${contactInfo.phone}`}
                    >
                      {formatPhoneNumber(contactInfo.phone)}
                    </a>
                  </li>
                </ContactInfoItem>
              </ul>
            </div>
            <div className="lg:w-5/12 sm:w-6/12 w-full">
              <h3 className="sm:text-3xl text-2xl">Send us a message</h3>
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ContactPage;
